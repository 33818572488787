<template>
  <div class="no-found">
    <b-container class="main my-5">
      <div class="m-auto text-center txt py-5">
        <!--<h1 class="mb-2 title1">404</h1>-->
        <p class="mb-3">
          閣下欲瀏覽之網頁已不存在,閣下可按這裡返回<b-link rel="" to="/zh-hant/" title="首頁">主頁</b-link>。
        </p>
        <p class="mb-3">
          阁下欲浏览之网页已不存在。阁下可按这里返回<b-link rel="" to="/zh-hans/" title="主页">主页</b-link>。
        </p>
        <p>
          The page you requested is not available. Please click here to return to
          <b-link rel="" to="/en/" title="homepage">homepage</b-link>。
        </p>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isCSR } from '@/utils'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  name: '404',
  data() {
    return {
      isMounted: false
    }
  },
  mixins: [Lifecycle],
  components: {},
  computed: {
    ...mapState('common', ['menu']),
    alreadyInited: function() {
      return !!this.menu
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  mounted() {
    this.$bar.start()
    this.fetchData().finally(() => this.$bar.finish())
    // this.fetchData()
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([this.fetchInitData()])
          .finally(() => {
            this.handleInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    handleInit() {}
  }
}
</script>
